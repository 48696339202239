import React from 'react';
import { Helmet } from 'react-helmet';

function ContinentMetadata(props) {
    const currentUrl = "https://bondedworld.com" + props.pathname;

    const title = "BondedWorld - " + props.name + " Guide";
    const image = props.image;

    const description = props.description;

    const hashtag = "#bondedworld";
    const facebookAppId = "987471025050482";

    return (
        <Helmet>
            <title>{ title }</title>
            <meta property="title" content={ title } />
            <meta name="description" content={ description } />
            <meta property="image" content={ image } />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={ title } />
            <meta property="og:hashtag" content={ hashtag } />
            <meta property="og:image" content={ image } />
            <meta content="image/*" property="og:image:type" />
            <meta property="og:url" content={ currentUrl } />
            <meta property="og:site_name" content={ title } />
            <meta property="og:description" content={ description } />
            <meta property="fb:app_id" content={ facebookAppId } />

            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content={ currentUrl } />
            <meta name="twitter:title" content={ title } />
            <meta name="twitter:description" content={ description } />
            <meta name="twitter:image" content={ image } />
        </Helmet>
    )
}

export default ContinentMetadata;
