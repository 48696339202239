import React, { Component } from 'react';
import './Guides.css';

import { Nav, Navbar, NavItem, NavLink, NavbarToggler, NavbarBrand, Collapse } from 'reactstrap';
import { Link } from 'gatsby';

import MultiImageHeader from '../elements/MultiImageHeader';
import RenderCard from '../elements/render/RenderCard';
import TopIcon from '../elements/TopIcon';

import ContinentMetadata from '../../metadata/ContinentMetadata';

class ContinentGuide extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isNavOpen: false
        }

        this.toggleNav = this.toggleNav.bind(this);
        this.closeNav = this.closeNav.bind(this);

        this.topRef = React.createRef();
        this.descriptionRef = React.createRef();
        this.destinationsRef = React.createRef();
        this.tripsRef = React.createRef();
    }

    toggleNav() {
        this.setState({ isNavOpen: !this.state.isNavOpen });
    }

    closeNav() {
        this.setState({ isNavOpen: false });
    }

    render() {
        const RenderNavTrip = ({ trips }) => {
            if (trips !== undefined && trips !== null && trips.length > 0) {
                return (
                    <React.Fragment>
                        <NavItem>
                            <NavLink href="#" onClick={() => { this.tripsRef.current.scrollIntoView() }}>Trips</NavLink>
                        </NavItem>
                    </React.Fragment>
                )
            } else {
                return (<div></div>);
            }
        }

        const RenderTrips = ({ trips }) => {
            if (trips !== undefined && trips !== null && trips.length > 0) {
                return (
                    <React.Fragment>
                        <div className="city-topic">
                            <div className="col-12 col-lg-11 ms-auto me-auto" >
                                <h1 id="trips" ref={ this.tripsRef }>Trips</h1>
                            </div>
                            <div className="row" style={{ marginTop: '20px' }}>
                                <RenderCard data={ trips } pathname={ this.props.pathname } />
                            </div>
                        </div>
                    </React.Fragment>
                )
            } else {
                return (<div></div>);
            }
        }

        return (
            <React.Fragment>
                <ContinentMetadata image={ this.props.continent.images[0] } name={ this.props.continent.name } description={ this.props.continent.description }
                    pathname={ this.props.pathname } />
                <div id="page-top" ref={ this.topRef } className='header-topic'>
                    <TopIcon />
                    <MultiImageHeader images={ this.props.continent.images } name={ this.props.continent.name } thumb={ this.props.continent.thumb } />
                    <div className="header-text">
                        <h1 className="header-title">{ this.props.continent.name }</h1>
                    </div>
                </div>

                <Navbar light sticky="top" className="bg-light border" expand="sm">
                    <div className="container">
                        <NavbarBrand className="d-block d-sm-none" href="" onClick={() => {
                                this.topRef.current.scrollIntoView();
                                this.closeNav(); }}>
                            { this.props.continent.name }
                        </NavbarBrand>
                        <NavbarToggler onClick={ this.toggleNav } />
                        <Collapse isOpen={ this.state.isNavOpen } navbar >
                            <Nav navbar>
                                <NavItem>
                                    <NavLink href="#" onClick={() => {
                                            this.descriptionRef.current.scrollIntoView();
                                            this.closeNav(); }}>Description</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#" onClick={() => {
                                            this.destinationsRef.current.scrollIntoView();
                                            this.closeNav(); }}>Destinations</NavLink>
                                </NavItem>
                                <RenderNavTrip trips={ this.props.continent.trips } />
                            </Nav>
                            <Nav className="ms-auto" navbar>
                                <NavItem>
                                    <NavLink tag={ Link } to="/" >Home</NavLink>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </div>
                </Navbar>

                <div className="container">
                    <div className="city-header">
                        <div id="description" ref={ this.descriptionRef } className="col-12 col-sm-10 col-lg-9 ms-auto me-auto" style={{ marginTop: '40px' }}>
                            { this.props.continent.description }
                        </div>
                    </div>

                    <div style={{ textAlign: 'left' }}>
                        <div className="city-topic">
                            <div className="col-12 col-lg-11 ms-auto me-auto" >
                                <h1 id="destinations" ref={ this.destinationsRef }>Destinations</h1>
                            </div>
                            <div className="row" style={{ marginTop: '20px' }} >
                                <RenderCard data={ this.props.continent.destinations } pathname={ this.props.pathname } />
                            </div>
                        </div>

                        <RenderTrips trips={ this.props.continent.trips } />
                    </div>
                </div>
            </React.Fragment>

        );
    }
}

export default ContinentGuide;
