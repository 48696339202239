import React from 'react';

export const NorthAmerica = {
    "name": "North America",
    "link": "north-america",

    "thumb": "https://live.staticflickr.com/65535/50380728507_cb61520171_t.jpg",

    "images": [
        "https://live.staticflickr.com/65535/50380728507_c6f0b6b85f_o.jpg",
        "https://images.unsplash.com/photo-1580094333632-438bdc04f79f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1280&q=80",
        "https://cdn.pixabay.com/photo/2015/11/05/23/02/chichen-itza-1025099_1280.jpg",
        "https://cdn.pixabay.com/photo/2013/05/09/22/07/virgin-river-110160_1280.jpg",
        "https://images.unsplash.com/photo-1584908917822-6799d69a72cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1280&q=80",
        "https://images.unsplash.com/photo-1518950957614-73ac0a001408?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1280&q=80",
        "https://cdn.pixabay.com/photo/2017/03/17/11/50/havana-2151324_1280.jpg",
        "https://images.unsplash.com/flagged/photo-1574342961277-590aa4596552?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1280&q=80"
    ],

    "description": "The land of extremes. This continent offers a multitude of cultures, traditions and civilizations to explore. From the super developed United States, with amazing natural landscapes like the Grand Canyon and the Yosemite National Parks, and the super developed cities of New York and Los Angeles, to the Latin American countries, where you can search and explore the amazing culture of Mexico and its ancient tribes, the natural paradise of Costa Rica and the amazing beaches of Cuba and the other Caribbean Countries. Each one of them has something different to offer and it's worth a visit there.",

    "destinations": [
        {
            "name": "Havana",
            "link": "havana",
            "image": "https://live.staticflickr.com/65535/50495093076_0d73834441_o.jpg"
        },
        {
            "name": "Miami",
            "link": "miami",
            "image": "https://live.staticflickr.com/65535/50495093061_30622131d5_o.jpg"
        }
    ]
}
